.background_gradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7)),
    url("/src/assets/images/banner.png");
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
}

.card_container {
  background: #fff;
  padding: 25px 35px;
  border-radius: 10px;
  height: 100%;
  border: 3px solid #A74FF2;
}
Input{
border-color:  #00aae4 !important;
}
.pre_sale_button {
  width: 100% !important;
  text-decoration: underline !important; /* Adds underline */
  text-underline-offset: 6px !important; /* Adjusts the space between text and underline */
}

input::placeholder {
  color: #0a0a08 !important;
  font-size: 10px !important;
}

.coupon_input {
  border: 2px solid #A74FF2 !important;
  border-radius: 6px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.coupon_input p {
  cursor: pointer;
  color: #0a0a0850 !important;
  font-weight: 500;
  font-size: 12px !important;
}

.svg_icon {
  width: 2em !important;
  height: 2em !important;
}
.prograssbar{
  width: 370px;
}
@media (max-width: 557px) {
  .prograssbar{
    width: 300px;
  }
}
@media (max-width: 410px) {
  .prograssbar{
    width: 200px;
  }
}
.affialiteform{
  max-width:500px ;
}
@media (max-width: 550px) {
  .affialiteform{
    max-width:100% ;
  }
}
@media (max-width: 1200px) {
  .coupon_input {
    padding: 7px 12px;
  }

  .coupon_input p {
    font-size: 10px !important;
  }

  .svg_icon {
    width: 1em !important;
    height: 1em !important;
  }

  .card_container {
    padding: 15px 25px;
  }
}

.coupon_input input {
  padding: 7px 100px 7px 15px !important;
}

.MuiTypography-root {
  color: #013e87;
}

.btn-blue{
  padding: 8px;
  background-color: #00aae4;
  color: #ffd689;
  border-radius: 5px;
  border: none;
  height: 31px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 150px;
  justify-content: center;

}
.btn-blue:hover{
  background-color: #052e5ef5;
}

.btn-blue-new{
  padding: 8px;
  background-color: #A74FF2;
  color: #ffffff;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  height: 31px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 155px;
  justify-content: center;

}
.btn-blue-new:hover{
  background-color: #00aae4;
}

.btn-register{
  padding: 8px;
  background-color: #00aae4;
  color: #ffd689;
  border-radius: 5px;
  border: none;
  height: 31px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 150px;
  justify-content: center;
}
.btn-register:hover{
  background-color: #052e5ef5;
}
.module-input{
  padding: 7px 12px;
  border: 2px solid #00aae4;
  border-radius:25px;
  height: 40px;
  width: 180px;
}
.module-input-email{
  padding: 7px 12px;
  border: 2px solid #00aae4;
  border-radius:25px;
  height: 40px;
  width: 100%;
}
.module-button{
  padding: 7px 12px;
  border-radius:25px;
  border: 2px solid #A74FF2;
  height: 40px;
  background-color: #00aae4;
  color: white;
  cursor: pointer;
  width: 150px;
}
.module-button:hover{
  background-color: #052e5ef5;

}
.multipleProjectButton
{
  color: #fff !important;
  text-decoration: none !important;
  background: #00C77D !important;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

